import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import TechnologyHeader from '../../StaticQueries/TechnologyHeader'
import MusicTechnologyGraphic from '../../StaticQueries/MusicTechnologyGraphic'

//import Typography from '@material-ui/core/Typography'
import Typography from 'typography'   // Gatsby Typography
import irvingTheme from 'typography-theme-irving'

const typography = new Typography(irvingTheme)


const styles = theme => ({
	paper: {
		maxWidth: 1700,
		margin: 'auto',
		overflow: 'hidden',
		[theme.breakpoints.up('sm')]: {
			minWidth: 600,
		},
		[theme.breakpoints.up('lg')]: {
			minWidth: 936,
		},
	},
	searchBar: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},
	block: {
		display: 'block',
	},
	addUser: {
		marginRight: theme.spacing.unit,
	},
	contentWrapper: {
		margin: '40px 16px',
		// height: 368,
	},
	container: {
		padding: '48px 36px 48px',
	},
})


function TechnologyContent({ classes }) {
	return (
		<>
			<div className={classes.container}>
				<Paper className={classes.paper}>

					<AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
						{/* <h1 align="center" style={{ color: 'green' }}>-= M°U°S°I°C° ≡≡≡ T°E°C°H°N°O°L°O°G°Y =-</h1>
							<h1 align="center" style={{ color: 'green' }}>-= C°O°M°P°O°S°I°T°I°O°N ≡≡≡ &amp; P°R°O°D°U°C°T°I°O°N =-</h1>
						*/}
						<TechnologyHeader />
					</AppBar>

					<div className={classes.contentWrapper} style={{color: 'blue'}}>
						<MusicTechnologyGraphic/>
						
						{/* <Typography color="textSecondary" align="justify"> */}
						<typography color="textPrimary" align="justify">
							<br/>
							<h2 style={{color: 'green'}}>HARDWARE:</h2>
							<hr />
								WORK IN PROGRESS...
							<br/>
							<br/>
							<br/>
							<h2 style={{color: 'green'}}>SOFTWARE:</h2>
							<hr/>
								WORK IN PROGRESS...
							<br/>
							<br/>
							<br/>
						</typography>
						{/* </Typography> */}
					</div>
				</Paper>
			</div>
		</>
	)
}

TechnologyContent.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(TechnologyContent)

/*
	<Img fluid={props.data.IntellectLogo.childImageSharp.fluid}
		alt='Intellect Logo'
		style={{
				position: 'absolute',
				top: '0',
				left: '0',
				transition: 'opacity 0.5s',
				transitionDelay: '0.5s',
				opacity: '1',
				width: '50%',
				height: '50%',
				maxHeight: 'calc(50vh - 4rem)',
				margin: '1rem',
				objectFit: 'cover',
				objectPosition: 'center'
		}}
		imgStyle={{ objectFit: 'contain' }}
	/>
*/
