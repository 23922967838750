import React from 'react'
import PropTypes from 'prop-types'
import Layout from 'components/Layout'
import TechnologyContent from 'components/TechnologyContent'

function TechnologyPage({ location }) {
	const pageTitle = location ? location.pathname.replace(/\//g, '') : ''

	return (
		<Layout id="Top" location={location} title={pageTitle}>
			<TechnologyContent />
		</Layout>
	)
}

TechnologyPage.propTypes = {
	location: PropTypes.object,
}

export default TechnologyPage
